import(/* webpackMode: "eager" */ "/home/runner/work/agiletelehealth-website/agiletelehealth-website/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/agiletelehealth-website/agiletelehealth-website/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"fallback\":[\"sans-serif\"],\"display\":\"swap\",\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/agiletelehealth-website/agiletelehealth-website/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Noto_Serif\",\"arguments\":[{\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\"],\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"fallback\":[\"sans-serif\"],\"display\":\"swap\",\"variable\":\"--font-noto-serif\"}],\"variableName\":\"notoSerif\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/agiletelehealth-website/agiletelehealth-website/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/agiletelehealth-website/agiletelehealth-website/src/components/ErrorParamsMessageEffect/ErrorParamsMessageEffect.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/agiletelehealth-website/agiletelehealth-website/src/styles/globals.css");
