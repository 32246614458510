'use client';

import { useSearchParams } from 'next/navigation';

import ErrorToastEffect from './ErrorToastEffect';

export interface PromoCodeValidatorProps {
  children: React.ReactNode;
}

export const ErrorParamsMessageEffect = () => {
  const params = useSearchParams();

  const error = params.get('error');

  if (!error) {
    return null;
  }

  return <ErrorToastEffect error={error} />;
};

export default ErrorParamsMessageEffect;
