import { isProductionEnvironment } from 'env';

export const WEIGHTLOSS_INITIAL_FORM_URL = isProductionEnvironment
  ? 'https://secure.openloophealth.com/appointments/embed_appt?dietitian_id=3247220&embed_form_id=1816228&form_only=true&primary_color=005F6C'
  : 'https://securestaging.gethealthie.com/appointments/embed_appt?dietitian_id=709153&embed_form_id=897053&form_only=true&primary_color=005F6C';

export const WEIGHTLOSS_FOLLOWUP_FORM_URL = isProductionEnvironment
  ? 'https://secure.openloophealth.com/appointments/embed_appt?dietitian_id=3247220&embed_form_id=1844654&form_only=true&primary_color=005F6C'
  : 'https://securestaging.gethealthie.com/appointments/embed_appt?dietitian_id=649550&embed_form_id=897052&form_only=true&primary_color=005F6C';

export const WL_SYNC_INITIAL_FORM_ID = isProductionEnvironment ? '1816228' : '897053';
export const WL_FOLLOWUP_FORM_ID = isProductionEnvironment ? '1844654' : '897052';

export const WL_ENCOUNTER_FORM_ID = isProductionEnvironment ? '1994441' : '1343543';
export const LABS_CUSTOM_MODULE_ID = isProductionEnvironment ? '25960521' : '11559398';

export const PATIENT_INSTRUCTIONS_FORM_ID = isProductionEnvironment ? '1915471' : '1092215';
export const RX_SKU_CUSTOM_MODULE_ID = isProductionEnvironment ? '25630898' : '10056013';
export const RX_NAME_CUSTOM_MODULE_ID = isProductionEnvironment ? '25630901' : '10056014';

export const AUTH_COOKIE_NAME = 'auth-token';
export const CHECKOUT_COOKIE_NAME = 'checkout_session';

export const CHECKOUT_EXPIRED_MESSAGE = 'Session is expired';
export const CHECKOUT_COMPLETED_MESSAGE = 'Session is completed';
export const CHECKOUT_NOT_FOUND_MESSAGE = 'Session not found';
export const UNEXPECTED_ERROR_MESSAGE = 'Unexpected error occurred';

export const CHECKOUT_EXPIRED_PARAM = 'checkout_expired';

export const AGILE_SUPPORT_EMAIL = 'support@agiletelehealth.com';

export const AGILE_SUPPORT_PHONE = '+15152182464';

export const REFERRER_PROMO_SESSION_STORAGE_KEY = 'referrer_promo';

export const PROMO_FALLBACK = isProductionEnvironment
  ? {
      id: 'promo_1QIuj7BUVTmZaKutOWSRsNJN',
      couponId: 'GQ5SqjoK',
      amount_off: 7500,
      percent_off: null,
      promo_code: 'HELLO',
    }
  : {
      id: 'promo_1QIud3BUVTmZaKutJsrFjfzl',
      couponId: 'cIxFcK4m',
      amount_off: 7500,
      percent_off: null,
      promo_code: 'HELLOSTG',
    };

export const AUTOMATED_PAYMENT_REMINDER_UTM_PARAMS = {
  utm_medium: 'email',
  utm_source: 'internal',
  utm_campaign: 'auto_email_post_booking_confirmation_enrollment_110624',
};

export const NOTIFICATIONS_SCHEDULE_GROUP_NAME = 'NotificationScheduleGroup';

/**
 * CONFIG PARAMETERS AND SECRETS
 */

const PREFIX = '/agile';

const STRIPE_INTEGRATION_PREFIX = `${PREFIX}/integrations/stripe`;
const HEALTHIE_INTEGRATION_PREFIX = `${PREFIX}/integrations/healthie`;
const QUEUES_PREFIX = `${PREFIX}/resources/queues`;

export const HEALTHIE_API_URL_PARAMETER_NAME = `${HEALTHIE_INTEGRATION_PREFIX}/api-url`;
export const HEALTHIE_API_KEY_SECRET_NAME = `${HEALTHIE_INTEGRATION_PREFIX}/api-key`;

export const STRIPE_PUBLISHABLE_KEY_PARAMETER_NAME = `${STRIPE_INTEGRATION_PREFIX}/publishable-key`;
export const STRIPE_API_KEY_SECRET_NAME = `${STRIPE_INTEGRATION_PREFIX}/api-key`;
export const STRIPE_WEBHOOK_SECRET_SECRET_NAME = `${STRIPE_INTEGRATION_PREFIX}/webhook-secret`;

export const SUBMITTED_PATIENTS_QUEUE_URL_PARAMETER_NAME = `${QUEUES_PREFIX}/submitted-patients-queue-url`;
export const SUBMITTED_FORM_ANSWER_GROUP_QUEUE_URL_PARAMETER_NAME = `${QUEUES_PREFIX}/submitted-form-answer-group-queue-url`;
export const SUBMITTED_DOCUMENT_QUEUE_URL_PARAMETER_NAME = `${QUEUES_PREFIX}/submitted-document-queue-url`;
export const UPDATE_SUBSCRIPTION_QUEUE_URL_PARAMETER_NAME = `${QUEUES_PREFIX}/update-subscription-queue-url`;
export const SUBMITTED_RX_ORDER_QUEUE_URL_PARAMETER_NAME = `${QUEUES_PREFIX}/submitted-rx-order-queue-url`;
export const UPDATED_SUBSCRIPTION_EVENT_QUEUE_URL_PARAMETER_NAME = `${QUEUES_PREFIX}/updated-subscription-event-queue-url`;
