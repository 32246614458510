'use client';

import { useEffect } from 'react';

import { formatErrorMessage } from 'utils';
import { showToast } from 'utils/showToast';

interface ErrorToastEffectProps {
  error: string | null;
}

const ErrorToastEffect = ({ error }: ErrorToastEffectProps) => {
  useEffect(() => {
    if (error) {
      const formattedError = formatErrorMessage(error);

      showToast('error', formattedError, {
        style: {
          top: '6rem',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default ErrorToastEffect;
